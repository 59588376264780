.slider{
    max-width: 1200px;
    height: 360px;
    background: #54203b;
    padding: 70px 0 0;
    position: absolute;
    top: 180px;
    left: 50%;
    margin: auto auto auto -600px;
}




.slider_image{
    width: 220px;
    height: 220px;
    background: #ccc;
    margin-left: 15px;
    cursor: pointer;
}

.slider_image:hover{
    opacity: 0.9;
}

@media (max-width: 1200px){
    .slider{
        display: none;
    }
}