.menu ul {
     margin: auto;
     text-align: center;
}

.menu{
    margin-top: 50px;
    margin-bottom: 20px;
}


.actionbar{
    display: none;
    border-bottom: 1px solid #A1A1A1;
    background: #54203b;
    opacity: 0.9;
    width: 100%;
    height: 50px;
    cursor: pointer;
}
.actionbar p{
    color: #fff;
    padding-top: 12px;
    padding-left: 5px;
    font-size: 110%;
}

.menuItem{
    display: inline-block;
    width: 165px;
    color: #54203b;
    font-size: 18px;
    text-align: center;
    text-decoration: none;

}

.menuItem a{
    color: #54203b;
    text-align: center;
    text-decoration: none;
    opacity: 0.8;
}

.menuItem a:hover{
    color: #54203b;
    text-align: center;
    text-decoration: none;
    opacity: 1;
}

@media (max-width: 1200px){
    .menu{
        display: none;
    }

    .menuMobile{
        margin-top: 0px;
        background: #54203b;
        width: 100%;
        min-height: 50px;
    }

    .menuMobile ul{
        width: 100%;
        padding: 0;
        padding-bottom: 20px;
    }

    .menuItem{
        height: 50px;
        display: block;
        width: 100%;
        padding-top: 20px;

    }
    .menuItem a{
        color: #fff;
    }
    .menuItem a:hover{
        color: #fff;
    }

    .actionbar{
        margin-top: 50px;
        display: block;
    }
}