.languagecontainer{
    min-height: 20%;
    background: #54203b;
    margin:0;
}

.lanImg{
    opacity: 0.8;
}

.lanImg:hover{
    opacity: 1;
}