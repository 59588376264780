.studentImage{
    width: 200px;
    height: 200px;
    border-radius: 125px;
}

.studentName{
    padding-top: 90px;
}

@media (max-width: 768px){
    .qrightfirst{
        display: none;
    }
    .qrightsecond{
        display: block;
    }
}
@media (min-width: 768px){
    .qrightfirst{
        display: block;
    }
    .qrightsecond{
        display: none;
    }
}