p,a,li,td,h3,h2,h1,b,u,i,Link{
    font-family: Arial;
    color: #54203b;
}


.content{
    max-width: 1200px;
    min-height: 300px;
    margin: 550px auto auto;
}

.spacertopsmall{
    margin-top: 50px;
}

.spacerDesktop{
    height: 50px;
    width: 1px;
}
.react-images__view-image--isModal{
    display: inline;
    z-index: 7;
}
.react-images__blanket{
    z-index: 6;
}
.css-yrspe{
    z-index: 4 !important;
}
.css-1ycyyax{
    z-index: 5 !important;
}

.spacerMobile{
    height: 30px;
    width: 1px;
}



@media (max-width: 1200px){
    /*
    Mobil
     */
    .content{
        margin-top: 170px;
    }

    .noticeMobile{
        display: block;
    }
    .noticeDesktop{
        display: none;
    }
    .spacerMobile{
        display: block;
    }
    .spacerDesktop{
        display: none;
    }
    .footer{
        position: inherit;
        text-align: center;
        margin-top: 15px;
        opacity: 0.9;
    }
}
@media (min-width: 1200px){
    /*
    Desktop
     */
    .noticeMobile{
        display: none;
    }
    .noticeDesktop{
        display: block;
    }
    .spacerMobile{
        display: none;
    }
    .spacerDesktop{
        display: block;
    }
    .footer{
        position: inherit;
        text-align: center;
        margin-top: 15px;
        opacity: 0.9;
    }
}