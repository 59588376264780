/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 3;
}

/* Style the icon bar links */
.icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
    background-color: #000;
}

.facebook {
    background: #3B5998;
    color: white;
}

.fa-facebook{
    color: white;
}

.instagram {
    background: #e84770;
    color: white;
}
